/* Global Styles */
body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
  }
.marg-top{
    margin-top: 100px;
}
  h1, h2, h3 {
    color: #000000;
  }

  ul, ol {
    margin: 20px 0;
    padding-left: 20px;
  }
  li{
    font-size: 16px;
  }

  a {
    color: #538B3E;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  /* Page-specific Styles */
  .terms-and-conditions {
    background-color: #fff3e0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }

  /* Section Styles */
  section {
    margin-bottom: 20px;
  }

  section h2 {
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
    color: #538B3E;
  }

  section ul, section ol {
    margin: 10px 0;
  }

  section ul {
    list-style-type: disc;
  }

  section ol {
    list-style-type: decimal;
  }

  /* Contact Info Styles */
  .contact-info ul {
    list-style: none;
    padding: 0;
  }

  .contact-info ul li {
    margin-bottom: 10px;
  }

  .contact-info ul li strong {
    display: inline-block;
    width: 100px;
  }

  /* Button Styles */
  button, .btn {
    background-color: #538B3E;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  button:hover, .btn:hover {
    background-color: #3e6a2b;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    body {
      padding: 10px;
    }

    .terms-and-conditions {
      padding: 15px;
    }

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }
  }
