.content-editor .section {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 1.5rem;
    background-color: #ffffff;
  }

  .content-editor .section h2 {
    border-bottom: 2px solid #538B3E;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    color: #538B3E;
  }

  .content-editor .form-group {
    margin-bottom: 1.5rem;
  }

  .content-editor .form-group label {
    font-weight: bold;
  }

  .content-editor .form-control {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
  }

  .content-editor .faq-item {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 1rem;
    background-color: #f8f9fa;
    margin-bottom: 1rem;
  }

  .content-editor .btn {
    background-color: #28a745;
    border-color: #28a745;
  }

  .content-editor .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
  }

  .content-editor .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
