/* UserForm.css */

.usermanagement {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.usermanagement > div {
    margin-bottom: 15px;
}

.usermanagement label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.usermanagement input[type="text"],
.usermanagement input[type="email"],
.usermanagement input[type="password"],
.usermanagement select {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.usermanagement button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.usermanagement button:hover {
    background-color: #0056b3;
}

.usermanagement button[type="button"] {
    background-color: #6c757d;
}

.usermanagement button[type="button"]:hover {
    background-color: #5a6268;
}

.usermanagement h4 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.usermanagement .address-group {
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #e9ecef;
}

.usermanagement .address-group > div {
    margin-bottom: 10px;
}
