
.single-products-box {
    position: relative;
    margin-bottom: 30px;

    overflow: hidden;

}

.single-products-box .products-image {
    text-align: center;
    position: relative;
    overflow: hidden;
}

.single-products-box .products-image a {
    position: relative;
    display: block;
}

.single-products-box .products-image a img {
    -webkit-transition: .8s;
    transition: .8s;
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 12px;
}

.single-products-box .products-image a .hover-image {
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(1.07);
    transform: scale(1.07);
}

.single-products-box .products-image .products-button {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
}

.single-products-box .products-image .products-button ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.single-products-box .products-image .products-button ul li {
    margin-bottom: 8px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.single-products-box .products-image .products-button ul li button {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    color: #000000;
    position: relative;
    background: #ffffff;
    padding: 12px;
    border: none;
}

.single-products-box .products-image .products-button ul li button:hover {
    color: #649E4E;
}

.single-products-box .products-image .products-button ul li button .tooltip-label {
    right: 110%;
    top: 45%;
    -webkit-transform: translateX(0) translateY(-45%);
    transform: translateX(0) translateY(-45%);
    position: absolute;
    padding: 0 10px;
    line-height: 28px;
    font-size: 12px;
    letter-spacing: .3px;
    visibility: hidden;
    white-space: nowrap;
    opacity: 0;
    color: transparent;
    background-color: transparent;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    background-color: #649E4E;
    color: #ffffff;
}

.single-products-box .products-image .products-button ul li button .tooltip-label::before {
    /* content: "\f356";
    font-family: "Font Awesome 6 Pro"; */
    color: #649E4E;
    top: 50%;
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    right: -13px;

    font-weight: normal;
    font-style: normal;
    font-size: 20px;
}

.single-products-box .products-image .products-button ul li button:hover .tooltip-label {
    visibility: visible;
    opacity: 1;
    right: 135%;
}

.single-products-box .products-image .products-button ul li:last-child {
    margin-bottom: 0;
}

.single-products-box .products-image .products-button ul li:nth-child(1) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.single-products-box .products-image .products-button ul li:nth-child(2) {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.single-products-box .products-image .products-button ul li:nth-child(3) {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.single-products-box .products-image .sale-tag {
    position: absolute;
    left: 20px;
    top: 20px;
    background-color: red;
    color: #ffffff;
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    border-radius: 50%;
    z-index: 2;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
}

.single-products-box .products-image .new-tag {
    position: absolute;
    left: 20px;
    top: 20px;
    background-color: green;
    color: #ffffff;
    text-align: center;
    z-index: 2;
    padding: 1px 7px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
}

.single-products-box .products-content {

    position: relative;
}

.single-products-box .products-content h3 {
    margin-bottom: 0;
    padding: 20px 0px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #000000;
    text-decoration: none;
    text-align: center;
}



/* .single-products-box .products-content h3 a:hover {
  color: #649E4E;
} */

.single-products-box .products-content .price {
    margin-top: 8px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 12px;
}

.single-products-box .products-content .price .old-price {
    text-decoration: line-through;
    color: #999999;
    font-weight: 500;
}

.single-products-box .products-content .star-rating {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #f49f0b;
}

.single-products-box .products-content .star-rating i {
    display: inline-block;
    margin-left: -2px;
}

.single-products-box .products-content .add-to-cart {
    text-decoration: none;

    left: 0;
    bottom: 0;
    text-transform: uppercase;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: inline-block;
    color: #ffffff;
    background: #538B3E;

    opacity: 1;

    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    font-size: 14px;
    font-weight: 600;
    width: 100%;

    padding: 12px;
    border-radius: 12px;
    text-align: center;
}

/* .single-products-box .products-content .add-to-cart:hover {
  color: #649E4E;
} */

.single-products-box:hover .products-content .price {
    opacity: 1;
    visibility: visible;
}

/* .single-products-box:hover .products-content .add-to-cart {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  visibility: visible;
} */

.single-products-box:hover .products-image a .main-image {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(1.07);
    transform: scale(1.07);
}

.single-products-box:hover .products-image a .hover-image {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.single-products-box:hover .products-image .products-button ul li {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

#view_all {

    display: inline-block;
    outline: 0;
    cursor: pointer;
    text-align: center;
    border: 1px solid #babfc3;
    padding: 7px 16px;
    min-height: 36px;
    min-width: 36px;
    color: #202223;
    background: #ffffff;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 0px;
    text-decoration: none;


}

#view_all:hover {
    background: #f6f6f7;
    outline: 1px solid transparent;
}

.product_tab {
    padding: 40px 0;
}

.product_tab a {
    text-decoration: none;
    color: #9B9B9B;
    font-size: 24px;
    font-weight: 600;
}

.product_tab .activer {
    color: #000000;
}

.custom_goodies{
    background: url("../../../../public/images/Group 1000002695.png") no-repeat center center;
    background-size: contain;
    height: 500px;
    margin-top: 70px!important;
    margin-bottom: 70px;
}
.themebg{
    background: #EEFFE8;
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
  }
  @media screen and (max-width: 480px){
    .single-products-box .products-image a img{
        height: 200px;
    }
    .custom_goodies{
        height: 200px;
        margin-top: 0px !important;
        margin-bottom: 0px;
    }
  }