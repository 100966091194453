.contactform {

    border-left: 3px solid #538B3EBF;
}
.contactform form{
    max-width: 500px;
    margin: 30px;
    margin-top: 0px;
    background: #ffffff;
    padding: 30px;
  }
  .contactform p{
  font-size: 12px;
  }
  .contactform input,.contactform textarea{
    width: 100%;
    background: #00000000;
    margin-bottom: 30px;
    padding: 10px 0px;
    border: none;
    border-bottom: 2px solid #538B3E;
    color: #000000;
  }
  #submit_message {
    display: block;
    padding: 12px 0px;
    border: none;
    background: #538B3E;
    width: 100%;
    text-decoration: none;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
  }
  .contactform textarea::placeholder {
    color: #000000;
    opacity: 1; /* Firefox */
  }
  .contactform textarea::-ms-input-placeholder { /* Edge 12 -18 */
    color: #000000;
  }
  .contactform textarea:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
  }
  .contactform input::placeholder {
    color: #000000;
    opacity: 1; /* Firefox */
  }

  .contactform input::-ms-input-placeholder { /* Edge 12 -18 */
    color: #000000;
  }
  .contactform input:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
  }

  .error-message {
    color: #ff0000; /* Red color for error messages */
    margin-top: 5px;
    font-size: 14px;
  }
  .accordion-button:not(.collapsed){
    background: #538B3E;
    color: #ffffff;

  }
  .accordion-button:hover{
    color: #ffffff;
  }
  .accordionmy {
    background-color: #ffffff;
    border-radius: 12px;
    margin-top: 10px;
    color: #000000;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    font-weight: 600;
    transition: 0.4s;
}
.panel{
    padding-top: 30px;
    background-color: #ffffff;
    max-height: max-content;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    margin-bottom: 20px;
    font-size: 14px;
    text-align: left;
}
.accordionouter{
    padding: 0px 50px;
}
.accordion-button:not(.collapsed)::after{
    color: #ffffff;
}
.accordion-button:not(.collapsed)::after {
    background-image: url('/public/images/right-arrow-svgrepo-com.svg');
    transform: rotate(0deg);
}
/* blog css start */
.intro1 {
    text-align: center;
    padding: 30px;
}

.intro1 h6 {
    color: #649E4E;
}

.intro1 span {
    border-bottom: 3px solid #649E4E;
    border-radius: 0px 5px;
}

.intro img {
    width: 100%;
    height: 200px;
    background: #ccc;
    border-radius: 12px;
    object-fit: cover;

}

.intro h6 {
    padding-top: 10px;
}

.introinner {
    position: relative;
    overflow: hidden;
}

.intro .introtag {
    width: 60px;
    height: 80px;
    position: absolute;
    top: -10px;
    left: 10%;
    background: #00000000;
}

.introtag2 {
    position: absolute;
    top: 0;
    left: 10%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 30px;
    padding-top: 10px;
    color: #FFFFFF;

    font-size: 16px;

}


.moveribbonouter{
    position: relative;
    overflow: hidden;
    padding: 6px;
    height: 40px;
    width: 100%;

    color: #649E4E;
}
.moveribbon {

position: absolute;
display: flex;
animation: movingtext 10s linear infinite alternate-reverse;

align-items: center;

}
.moveribbonnew{
animation: movingtext1 10s linear infinite alternate-reverse;
}
.moverribboninner{
padding:  0px 20px;
display: flex;
justify-content: center;
align-items: center;
}
.moverribboninner i{
padding-right: 40px;
}
.bannerc1 {
    background: url("/public/images/banner/banner1.png") no-repeat center;
    background-size: cover;
    padding: 50px 30px;
    padding-left: 60px !important;
    color: #000000;
    border-radius: 12px;
}

.bannerc2 {
    background: url("/public/images/banner/banner2.png") no-repeat center;
    background-size: cover;
    padding: 50px 30px;
    padding-left: 60px !important;
    color: #000000;
    border-radius: 12px;
}
.btnstyle1 {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    border-radius: 10px;
    transition-property: background-color, border-color, color, box-shadow, filter;
    transition-duration: .3s;
    border: 1px solid transparent;
    letter-spacing: 2px;
    min-width: 160px;
    text-transform: uppercase;
    white-space: normal;
    font-weight: 700;
    text-align: center;
    padding: 16px 14px 18px;
    color: #fff;
    background: #538B3E;

    height: 48px;
    text-decoration: none;

}
.bannerc {
    padding: 100px 0px;
}
@media screen and (max-width: 430px){

    .footer_section_4{
        padding: 0;
    }
    .accordionouter{
padding: 0;
    }
    .contactform{
        border: none;
        box-shadow: 0px 0px 10px #538B3EBF;
        border-radius: 12px;
        margin-top: 30px;
    }
    .contactform form{


        margin: 0;
    }
    .mobile_section_contact{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        input{
            margin-bottom: 0px;
        }
    }
}
/* blog css end */