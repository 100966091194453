/* Container */
.containers {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header */
.header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
  color: #333;
}

/* Subheader */
.subHeader {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 600;
  color: #555;
}

/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

/* Table Headers */
.tableHeader {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  background-color: #eaeaea;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

/* Table Cells */
.tableCell {
  border: 1px solid #ddd;
  padding: 10px;
  vertical-align: top;
  font-size: 14px;
}

/* Image List */
.imageList {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

/* Image */
.image {
  width: 100px;
  height: auto;
  margin: 5px;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

/* List Items */
.li {
  width: fit-content;
}

/* Save Button */
.saveButton {
  display: block;
  margin: 20px auto;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s, transform 0.2s;
  text-transform: uppercase;
}

.saveButton:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .table {
    font-size: 12px;
  }
  .header, .subHeader {
    font-size: 20px;
  }
}
